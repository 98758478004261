.skyline {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
}

.error-toast {
    text-align: center;
    line-height: 36px;
    font-size: 18px;
}

.full-height {
    display: flex;
    flex-direction: column;
    align-items: center;    
    min-height: 100vh;
    background: #edf4f8;
}

.rel-z1 {
    position: relative;
    z-index: 1;
}

input {
    border-style: solid;
    border-radius: 30px;
    border-width: 2px;
    border-color: #07679A;
    text-align: center;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    background: #07679A;
    position: relative;
    z-index: 1;
}

input::placeholder {
    color: #ffffff;
    font-weight: 600;
}

.logo {
    margin-top: 90px;
    margin-bottom: 90px;
}