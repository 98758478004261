ion-popover {
    --width: 320px;
}

.succes-toast {
    text-align: center;
    line-height: 36px;
    font-size: 18px;
}

.form {
    max-width: 500px;
    margin: 0 auto;
}

.no-pad-sides {
    padding-left: 0px;
    padding-right: 0px;
}

.title-main {
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    width: 100%;
    font-weight: 700;
    margin-top: 45px;
    margin-bottom: 45px;
}

.title-label {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    width: 100%;
    font-weight: 700;
    margin-bottom: 15px;
    margin-top: 0px;
}

.input {
    border-style: solid;
    border-radius: 30px;
    border-width: 2px;
    border-color: #07679A;
    color: #07679A;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 15px;
    font-weight: 600;
    margin-left: auto;
    margin-right: auto;
}

.input.select-button {
    font-weight: 400;
}

input {
    --padding-start: 18px;
    --placeholder-color: rgba(7, 103, 154);
    --placeholder-font-weight: 400;
    --placeholder-opacity: 0.7;
}

.date-selected {
    width: 100%;
    text-align: center;
    margin-bottom: -10px;
    margin-top: -17px;
}

.button-select {
    font-size: 14px;
    line-height: 14px;
    width: 100%;
}

ion-button {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    --padding-top: 20px;
    --padding-end: 18px;
    --padding-bottom: 20px;
    --padding-start: 18px;
    --border-style: solid;
    --border-radius: 25px;
    --border-color: #07679A;
    --background-hover: darken(#07679A, 4);
}

ion-button.button-input {
    --background: #EEF4F8;
    --color: #07679A;
    --border-width: 2px;
    --background-activated: #EEF4F8;
    font-size: 18px;
    line-height: 18px;
    font-weight: 400;
}

ion-button.button-submit {
    --background: #07679A;
    --color: #fff;
    --border-width: 0px;
    --background-activated: #07679A;
    font-size: 15px;
    line-height: 15px;
    font-weight: 600;
}

ion-button.button-clear {
    --background: #eb445a;
    --color: #fff;
    --border-width: 0px;
    --background-activated: #07679A;
    font-size: 15px;
    line-height: 15px;
    font-weight: 600;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 30px;
    width: 100%;
}

.icon-clear {
    color: #07679A;
    font-size: 15px;
    line-height: 15px;
    font-weight: 600;
    padding-right: 20px;
    padding-top: 15px;
    width: 45px;
}

.flex-column {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

ion-text {
    text-align: left;
    width: 100%;
}

ion-textarea {
    color:rgba(7, 103, 154, 0.7)
}

ion-item {
    --background-focused-opacity: 0;
    --background-hover: transparent;
}

.marg-bot {
    margin-bottom: 5px;
}

ion-select {
    --padding-start: 15px;
    --padding-end: 15px;
    --placeholder-color: rgba(7, 103, 154);
    --placeholder-opacity: 0.7;
}

textarea {
    --padding-start: 15px;
    --padding-top: 0px;
    --padding-bottom: 0px;
    --placeholder-font-weight: 400;
    --placeholder-color: rgba(7, 103, 154);
    --placeholder-font-weight: 400;
    --placeholder-opacity: 0.7;
}

.relative {
    position: relative;
}

.sign-placeholder {
    position: relative;
    padding-top: 20px;
    padding-left: 20px;
    width: 50%;
    text-align: left;
    color: rgba(7, 103, 154, 0.7) !important;
    font-size: 15px !important;
}

.sigCanvas {
    border: 2px solid #07679A;
    border-radius: 25px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

input:-webkit-autofill,

input:-webkit-autofill:hover,

input:-webkit-autofill:focus

input:-webkit-autofill,

textarea:-webkit-autofill,

textarea:-webkit-autofill:hover

textarea:-webkit-autofill:focus,

select:-webkit-autofill,

select:-webkit-autofill:hover,

select:-webkit-autofill:focus {

  border:none !important;

  -webkit-text-fill-color: #07679A !important;

  /* -webkit-box-shadow: 0 0 0px 1000px #FFFFFF inset; */

  transition: background-color 5000s ease-in-out 0s;

}